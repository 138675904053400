<template>
  <div>
    <v-container fluid>
      <v-layout column wrap class="sheet">
        <v-layout align-center row justify-left>
          <v-alert :value="true" type="info" outline>
            Introducing Chaperones, a new type of rider that has permissions to
            use the CharterUP Driver mobile app to scan riders on and off any
            trip in their Customer Account. Chaperones should only be created
            when required by the contract.
          </v-alert>
        </v-layout>
        <v-layout align-center row justify-space-between class="mb-5">
          <v-flex shrink>
            <v-checkbox
              id="chaperonesListShowOnlyCustomerAccountChaperones"
              v-model="showOnlyCustomerAccountChaperones"
              :label="showOnlyLabel"
            />
          </v-flex>
          <v-flex shrink>
            <v-btn
              id="chaperonesListAddNew"
              class="btn-primaryaction add-new"
              @click="createItem"
            >
              Add New
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex grow>
            <v-data-table
              id="chaperonesListTable"
              :headers="headers"
              :items="filteredChaperones"
              item-key="externalId"
              :pagination.sync="pagination"
              @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            >
              <template #items="props">
                <td class="text-xs">
                  {{ props.item.firstName }} {{ props.item.lastName }}
                </td>
                <td class="text-xs">{{ props.item.email }}</td>
                <td class="text-xs">{{ props.item.phone }}</td>
                <td class="justify-begin align-center layout px-0">
                  <v-icon
                    :id="`chaperonesListEdit-${props.item.externalId}`"
                    small
                    class="mx-1"
                    @click="editItem(props.item)"
                  >
                    edit
                  </v-icon>
                  <ChaperonesDeleteDialog :chaperone="props.item" />
                  <v-icon
                    :id="`chaperonesListLink-${props.item.externalId}`"
                    small
                    class="mx-1"
                    @click="props.expanded = !props.expanded"
                  >
                    link
                  </v-icon>
                </td>
              </template>
              <template #expand="props">
                <v-autocomplete
                  :value="
                    props.item.customerAccounts.map(
                      (ca) => ca.customerAccountId
                    )
                  "
                  chips
                  deletable-chips
                  label="Linked Customer Accounts"
                  :items="customerAccounts"
                  item-text="name"
                  item-value="customerAccountId"
                  multiple
                  @change="changeCustomerAccounts(props.item, $event)"
                ></v-autocomplete>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChaperonesDeleteDialog from '@/components/ChaperonesDeleteDialog.vue'

export default {
  name: 'ChaperonesList',
  components: {
    ChaperonesDeleteDialog,
  },
  props: {
    customerAccountId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 5,
        sortBy: 'firstName',
        totalItems: 0,
      },
      deleteDialog: false,
      showOnlyCustomerAccountChaperones: true,
      headers: [
        {
          align: 'left',
          text: 'Name',
          value: ['firstName', 'lastName'],
        },
        {
          align: 'left',
          text: 'Email',
          value: 'email',
        },
        {
          align: 'left',
          text: 'Phone',
          value: 'phone',
        },
        {
          text: 'Actions',
          value: 'externalId',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      chaperones: 'chaperones/chaperones',
      customerAccounts: 'customerAccounts/customerAccounts',
      customerAccount: 'customerAccounts/customerAccount',
    }),
    filteredChaperones() {
      return this.chaperones.filter((c) => {
        return (
          c.customerAccounts
            .map((ca) => ca.customerAccountId)
            .includes(Number(this.customerAccountId)) ||
          !this.showOnlyCustomerAccountChaperones
        )
      })
    },
    showOnlyLabel() {
      return `Show only ${
        this.customerAccount(this.customerAccountId)?.name
      } chaperones`
    },
  },
  async mounted() {
    this.$store.dispatch('chaperones/fetchChaperones')
    this.$store.dispatch('customerAccounts/fetchCustomerAccounts')
  },
  methods: {
    changeCustomerAccounts(chaperone, newCustomerAccounts) {
      const oldCustomerAccounts = chaperone.customerAccounts.map(
        (ca) => ca.customerAccountId
      )
      newCustomerAccounts.forEach((ca) => {
        if (!oldCustomerAccounts.includes(ca)) {
          this.$store.dispatch('chaperones/linkCustomerAccount', {
            chaperone,
            customerAccount: this.customerAccount(ca),
          })
        }
      })
      oldCustomerAccounts.forEach((ca) => {
        if (!newCustomerAccounts.includes(ca)) {
          this.$store.dispatch('chaperones/unLinkCustomerAccount', {
            chaperone,
            customerAccount: this.customerAccount(ca),
          })
        }
      })
    },
    createItem() {
      const component = () => import('@/components/ChaperoneForm.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          title: 'New Chaperone',
        },
        component,
      })
    },
    editItem(item) {
      const component = () => import('@/components/ChaperoneForm.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          title: 'Edit Chaperone',
          chaperone: item,
        },
        component,
      })
    },
    deleteItem(item) {
      this.$store.dispatch('chaperones/deleteChaperone', item)
      this.deleteDialog = false
    },
  },
}
</script>
