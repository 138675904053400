<template>
  <div>
    <router-link
      id="`action-column-clickable-customer-account-new-tab`"
      target="_blank"
      :to="customerAccountDetailLink"
      class="no-underline"
    >
      {{ rowProps.item.customerAccountName }}
    </router-link>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    rowProps: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    customerAccountDetailLink() {
      let link = {
        name: 'customer-accounts.view',
        params: {
          id: this.rowProps.item.customerAccountId,
        },
      }
      return link
    },
  },
  methods: {
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
  },
}
</script>

<style lang="scss" scoped>
.no-underline {
  text-decoration: none;
}
</style>
