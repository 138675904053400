import Vue from 'vue'
import router from '@/router/index'
import { store } from '@/state/store'

export default async function (component) {
  const MenuDataMapper = function (row) {
    return [
      {
        icon: () => 'remove_red_eye',
        viewBox: '0 0 24 24',
        title: () => 'View',
        action: () => {
          const route = router.resolve({
            name: 'customer-accounts.view',
            params: { id: row.customerAccountId },
          })
          window.open(route.href, '_blank')
        },
        quickAccess: true,
      },
      {
        icon: () => 'edit',
        viewBox: '0 0 24 24',
        title: () => 'Edit',
        action: () => {
          const component = () =>
            import('@/components/CustomerAccountSidebar.vue')
          store.dispatch('app/openSidebarDialog', {
            data: {
              title: 'Edit Account',
              mode: 'edit',
              id: row.customerAccountId,
            },
            component,
          })
        },
        quickAccess: true,
      },
      {
        title: () => 'Remove from Parent',
        icon: () => 'clear',
        viewBox: '0 0 24 24',
        action: async () => {
          store.dispatch('app/openDialog', {
            component: () =>
              import('@/components/RemoveAccountFromParentForm.vue'),
            data: {
              enterpriseAccount: row.enterpriseAccount,
              teamAccount: row,
            },
          })
        },
        quickAccess: true,
      },
    ]
  }

  return Vue.component('WrappedActions', {
    props: {
      row: {
        type: Object,
        default: () => ({}),
      },
      handleAction: {
        type: Function,
        default: () => null,
      },
      rowProps: {
        type: Object,
        default: () => ({}),
      },
    },
    render(createElement) {
      return createElement(component, {
        props: {
          row: this.row,
          handleAction: this.handleAction,
          rowProps: this.rowProps,
          menu: new MenuDataMapper(this.row, this.rowProps, this.handleAction),
        },
      })
    },
  })
}
