<template>
  <v-container fluid class="base-table-padding-left-none">
    <h1></h1>
    <DataTable
      v-bind="tableProps"
      class="mt-2"
      add-new-button-label="Add User"
    />
  </v-container>
</template>

<script>
import customers from '@/services/customers'
import customerAccounts from '@/services/customerAccounts'
import { DateTime } from 'luxon'
import { authComputed } from '@/state/helpers'
import DataTable from '@/components/DataTable.vue'
import actionsWrapper from '@/utils/BaseActionsWrapper'
import BaseActionsRequiresWrapper from '@/components/BaseActionsRequiresWrapper.vue'
import CustomerAccountUserDetail from '@/components/CustomerAccountUserDetail.vue'
import GenericActionableColumn from '@/components/GenericActionableColumn.vue'
import CustomerAccountDetailLinkColumn from '@/components/CustomerAccountDetailLinkColumn.vue'
import CustomerAccountUserInviteColumn from '@/components/CustomerAccountUserInviteColumn.vue'
import { EventBus } from '@/utils/event-bus'

const formatDate = (item, row) => {
  let date = DateTime.fromISO(item, {
    zone: row['timeZone'] || 'local',
  })

  if (!date.isValid) {
    return ''
  }

  return date.toFormat('MM/dd/yyyy t ZZZZ')
}

export default {
  components: {
    DataTable,
  },
  filters: {
    formatTime(value, zone) {
      if (!value) return ''
      return DateTime.fromISO(value, { zone }).toLocaleString(
        DateTime.TIME_SIMPLE
      )
    },
    formatDateTime(time, stop) {
      if (!time) return '- · -'
      return `${DateTime.fromISO(time, { zone: stop.TimeZone })?.toLocaleString(
        DateTime.DATE_SHORT
      )} · ${DateTime.fromISO(time, {
        zone: stop.timeZone,
      })?.toLocaleString(DateTime.TIME_SIMPLE)}`
    },
  },
  data() {
    return {
      expand: false,
      requests: [],
      columns: [],
      tableProps: {
        detail: CustomerAccountUserDetail,
        tableId: 'customer_account_users_table_view',
        total: 0,
        currentPage: 1,
        perPage: 10,
        list: [],
        changePage: this.changePage,
        loading: true,
        columns: [],
        enableSavedViews: false,
        addNewEnabled: true,
        addNewHandler: () => {
          return this.$store.dispatch('app/openSidebarDialog', {
            component: () =>
              import('@/components/CustomerAccountUserSelector.vue'),
            data: {
              title: `Add User to ${this.customerAccount.name}`,
              customerAccountId: this.customerAccount.id,
            },
          })
        },
        collection: 'Customers',
        action: (params) => customers.getCustomers(params),
      },
    }
  },
  computed: {
    ...authComputed,
    customerAccount() {
      return this.$store.getters['customerAccounts/getCustomerAccountDetail']
    },
  },
  async mounted() {
    await this.getColumns()
    this.tableProps.columns.push(...this.columns)
    this.getTable()

    EventBus.$on('global-dialog-closed-action', () => {
      setTimeout(this.getTable, 500)
    })
  },
  methods: {
    async getColumns() {
      const wrappedBaseActions = await actionsWrapper(
        BaseActionsRequiresWrapper,
        'customer',
        ['edit-customer', 'delete-no-quick-view'],
        'customers'
      )

      this.columns = [
        {
          _t_id: '7e72062f',
          prop: '/',
          text: 'Actions',
          component: wrappedBaseActions,
          sort: false,
          filter: false,
          detail: false,
          type: 'actions',
        },
        {
          _t_id: '802c4331',
          prop: 'customerAccountName',
          text: 'Customer Account Name',
          component: CustomerAccountDetailLinkColumn,
          sort: false,
          filter: false,
          filterType: 'contains',
          type: 'text',
        },
        {
          _t_id: 'd4cf5378',
          prop: ['firstName', 'lastName'],
          text: 'Name',
          component: GenericActionableColumn,
          displayType: 'clickable-customer',
          sort: false,
          filter: false,
          filterType: 'contains',
          type: 'text',
        },
        {
          _t_id: '04239494',
          prop: 'email',
          text: 'Email',
          sort: false,
          filter: false,
          type: 'text',
        },
        {
          _t_id: '3a67f4d2',
          prop: 'title',
          text: 'Title',
          sort: false,
          filter: false,
          type: 'text',
          filterType: 'contains',
        },
        {
          _t_id: 'f5ffe953',
          prop: 'phone',
          text: 'Phone',
          sort: false,
          filter: false,
          type: 'text',
        },
        {
          _t_id: 'f5ffe942',
          prop: 'roles',
          text: 'Role',
          computedText: (item) => {
            return item.find((r) => r.roleName === 'customer_account_admin')
              ? 'Admin'
              : 'User'
          },
          sort: false,
          filter: false,
          type: 'text',
        },
        {
          _t_id: 'f5ffe947',
          prop: 'lastLogin',
          text: 'Last Login',
          computedText: formatDate,
          sort: false,
          filter: false,
          type: 'text',
        },
        {
          _t_id: 'f5ffe944',
          prop: '',
          text: '',
          component: CustomerAccountUserInviteColumn,
          sort: false,
          filter: false,
          type: 'text',
        },
      ]
    },
    async getTable() {
      this.tableProps.loading = true

      let userList = []
      try {
        const eventData = await customerAccounts.getCustomers(
          this.customerAccount.id
        )
        userList = eventData.data
      } catch (error) {
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: 'Error fetching customer account users',
        })
        this.tableProps.loading = false
      }

      this.tableProps.currentPage = this.currentPage

      this.tableProps.list = userList
      this.tableProps.list.forEach((item) => {
        item.deleteMe = this.handleDelete.bind(this)
      })
      this.tableProps.total = userList.length
      this.tableProps.loading = false
    },
    handleDelete(targetRow) {
      this.tableProps.list = this.tableProps.list.filter(
        (row) => row.customerId !== targetRow.customerId
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.time-off-container {
  position: relative;
  background-color: $blue-light;
}

::v-deep {
  .v-table__overflow {
    border: 1px solid $gray-light;
    margin-top: 15px;

    table.v-datatable {
      thead tr {
        height: 55px;
      }

      tbody {
        tr td:first-of-type {
          text-align: center;
        }
      }
    }
  }
}

.edit-button {
  width: auto;

  ::v-deep button {
    padding: 4px;
  }
}

.reservation-vehicle-selector ::v-deep {
  overflow: hidden;

  label,
  .v-icon {
    color: $primary;
  }

  .v-input__control {
    min-height: 40px !important;
    > .v-input__slot {
      max-height: 40px !important;
      min-height: 40px !important;
    }
  }
}

.status-chip {
  font-weight: bold;
  color: $white;
  font-size: 11px;
  padding: 4px 5px 4px 5px;
  border-radius: 8px;

  &.todo {
    background-color: $blue-dull;
  }
  &.enroute {
    background-color: $primary;
  }
  &.completed {
    background-color: $green;
  }
}

::v-deep .btn-primaryaction {
  margin-right: 1px !important;
}
</style>
