<template>
  <Sheet id="customer-account-detail">
    <v-layout row align-center justify-start ml-2>
      <v-flex shrink>
        <h1 style="display: inline-block; font-size: 24px">
          {{ customerAccountName }}
        </h1>
      </v-flex>
      <v-flex shrink>
        <CRTag
          v-if="customerAccountIsDemo"
          label="Demo"
          color="blueNewLighter"
          class="margin-l-2"
        />
      </v-flex>
      <v-flex grow />
    </v-layout>
    <div>
      <v-tabs v-model="selectedTab" class="mb-4 mt-2">
        <v-tab
          v-for="component in customerAccountComponents"
          :id="`customer-account-tab-${component.text.toLowerCase()}`"
          :key="component.text"
          class="mx-2"
        >
          {{ component.text }}
        </v-tab>
      </v-tabs>
      <keep-alive>
        <component
          :is="customerAccountComponents[selectedTab].value"
          :id="`${id}-customer-account-component-selector`"
          v-bind="customerAccount"
        />
      </keep-alive>
    </div>
  </Sheet>
</template>

<script>
import Sheet from '@/layouts/Sheet.vue'
import customerAccounts from '@/services/customerAccounts'
import { mapActions } from 'vuex'
import CustomerAccountSummary from '@/components/CustomerAccountSummary.vue'
import CustomerAccountRiderSettingsChaperones from '@/components/CustomerAccountRiderSettingsChaperones.vue'
import CustomerAccountUsersList from '@/components/CustomerAccountUsersList.vue'
import TeamAccountsList from '@/views/TeamAccountsList.vue'
import CustomerAccountDefaultSettings from '@/components/CustomerAccountDefaultSettings.vue'
import CustomerAccountSharedContacts from '@/components/CustomerAccountSharedContacts.vue'
import CustomerAccountRiderGroups from '@/components/CustomerAccountRiderGroups.vue'
import CustomerAccountRiders from '@/components/CustomerAccountRiders.vue'
import { EventBus } from '@/utils/event-bus'
import { CustomerAccountPurpose } from '@/utils/enum'
import CRTag from '@/cr/components/CRTag.vue'
import CustomerAccountRiderGroupsVue from '../components/CustomerAccountRiderGroups.vue'
import CustomerAccountRidersVue from '@/components/CustomerAccountRiders.vue'

export default {
  components: { Sheet, CRTag },
  props: {},
  data() {
    return {
      selectedTab: 0,
      loading: false,
    }
  },
  computed: {
    customerAccountComponents() {
      let baseComponents = [
        { text: 'Summary', value: CustomerAccountSummary },
        { text: 'Configuration', value: CustomerAccountDefaultSettings },
        { text: 'Users', value: CustomerAccountUsersList },
        { text: 'Teams', value: TeamAccountsList },
        { text: 'Shared Contacts', value: CustomerAccountSharedContacts },
        { text: 'Shuttles', value: CustomerAccountRiderSettingsChaperones },
      ]

      if (this.customerAccount?.hasRiderTracking) {
        baseComponents.push({
          text: 'Riders',
          value: CustomerAccountRiders,
        })
        baseComponents.push({
          text: 'Rider Groups',
          value: CustomerAccountRiderGroupsVue,
        })
      }

      return baseComponents
    },
    customerAccount() {
      return this.$store.getters['customerAccounts/getCustomerAccountDetail']
    },
    customerAccountIsDemo() {
      return this.customerAccount?.purpose === CustomerAccountPurpose.Demo
    },
    customerAccountName() {
      return this.customerAccount?.name || 'Customer Account'
    },
  },
  async mounted() {
    this.loadCustomerAccount()

    EventBus.$on('load-customer-account', () => {
      this.loadCustomerAccount()
    })
  },
  watch: {
    $route() {
      this.loadCustomerAccount()
    },
  },
  methods: {
    ...mapActions({
      setCustomerAccountDetail: 'customerAccounts/setCustomerAccountDetail',
    }),
    async loadCustomerAccount() {
      this.loading = true
      try {
        const customerAccountResponse = await customerAccounts.getCustomerAccount(
          this.$route.params.id
        )
        const customerAccount = customerAccountResponse.data

        const defaultSettingsResponse = await customerAccounts.getCustomerAccountDefaults(
          this.$route.params.id
        )
        customerAccount.defaults = defaultSettingsResponse.data
        const effectiveDefaultSettingsResponse = await customerAccounts.getCustomerAccountEffectiveDefaults(
          this.$route.params.id
        )
        customerAccount.effectiveDefaults =
          effectiveDefaultSettingsResponse.data

        this.setCustomerAccountDetail({
          customerAccount: customerAccount,
        })
      } catch (e) {
        console.error(e)
        this.$router.push({ name: 'customer-accounts' })
        this.$store.dispatch('app/showAlert', {
          message: 'Failed to load customer account',
          type: 'error',
        })
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  margin: 0;
}

.layout.row > *:not(:first-child) {
  margin-right: 0;
  margin-left: 8px;
}
</style>
