import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import {
  AddRiderPayload,
  InviteRiderPayload,
  UpdateRiderPayload,
} from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Imports riders for the customer with the given customer account ID.
   *
   * @param customerAccountId - The customer account ID to use to import riders.
   * @param payload - The riders to import.
   * @returns A promise that resolves to the result of importing the riders.
   */
  import(
    customerAccountId: number,
    payload: FormData,
    addAll = false,
    sendInviteEmail = false
  ): Promise<AxiosResponse> {
    const host = baseUrl()
    let url = `https://${host}/rider/import/${customerAccountId}`
    if (addAll) {
      url = url.concat('?addAll=true')
    } else {
      url = url.concat('?addAll=false')
    }
    if (sendInviteEmail) {
      url = url.concat('&sendInviteEmail=true')
    }
    return httpService.post(url, payload)
  },
  /**
   * Gets a rider import template
   *
   * @returns a promise that resolves to an AxiosResponse containing a Blob
   */
  importRiderTemplate(): Promise<AxiosResponse<Blob>> {
    const host = baseUrl()
    const url = `https://${host}/rider/import/template`
    return httpService.get(url, { responseType: 'blob' })
  },
  /**
   * Sends an invite email to the rider with the given id.
   *
   * @param payload - The payload containing the rider's id.
   * @returns A promise that resolves to the result of sending an invite email to the rider.
   */
  sendInviteEmail(payload: InviteRiderPayload): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/rider/invite`
    return httpService.post(url, payload)
  },
  /**
   * Exports the riders for a given customer account ID.
   *
   * @param customerAccountId - The customer account ID.
   * @returns A promise that resolves to a CSV of the riders to export.
   */
  exportRiders(customerAccountId: Number): Promise<AxiosResponse<Blob>> {
    const host = baseUrl()
    const url = `https://${host}/coachrail/v1/customer-accounts/${customerAccountId}/export-riders`
    return httpService.get(url)
  },
}
