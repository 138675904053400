<template>
  <div style="position: relative; margin-left: 7px">
    <v-layout class="default-settings-section max-w-684">
      <v-flex class="margin-l-5 margin-r-5 margin-b-4 max-w-660">
        <v-layout
          class="margin-t-3 margin-b-4 justify-space-between align-center"
        >
          <v-flex>
            <v-layout class="align-center">
              <v-avatar size="72" style="margin: 6px 12px 6px">
                <img
                  v-if="customerAccountAvatar"
                  width="72"
                  :src="customerAccountAvatar"
                />
                <v-icon v-else color="blue" size="72">person_outline</v-icon>
              </v-avatar>
              <p class="margin-a-0 font-28 text-gray-subtle-text-2">
                Default Settings
              </p>
            </v-layout>
          </v-flex>
          <p
            class="margin-a-0 text-primary margin-r-4 cursor-pointer"
            @click="openDetailSidebar"
          >
            Edit
          </p>
        </v-layout>
        <v-layout row>
          <v-flex xs6 class="padding-r-2">
            <div class="margin-t-3">
              <b>Quote Pricing Method</b>
              <div v-if="customerAccountPricingMethod.value">
                {{ pricingMethodDisplay }}
              </div>
              <div v-else>--</div>
            </div>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs6 class="padding-r-2">
            <div class="margin-t-3">
              <b>Discount %</b>
              <div v-if="customerAccountDiscountPercent.value">
                {{ discountPercentDisplay }}
              </div>
              <div v-else>--</div>
            </div>
          </v-flex>
          <v-flex xs6>
            <div class="margin-t-3">
              <b>Markup %</b>
              <div v-if="customerAccountMarkupPercent.value">
                {{ markupPercentDisplay }}
              </div>
              <div v-else>--</div>
            </div>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs6 class="padding-r-2">
            <div class="margin-t-3">
              <b>Payment Methods</b>
              <div v-if="customerAccountPaymentMethod.value">
                {{ paymentMethodDisplay }}
              </div>
              <div v-else>--</div>
            </div>
          </v-flex>
          <v-flex xs6>
            <div class="margin-t-3">
              <b>MARGE</b>
              <div v-if="customerAccountNeedsManualReferral.value !== null">
                {{ needsManualReferralDisplay }}
              </div>
              <div v-else>--</div>
            </div>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs6 class="padding-r-2">
            <div class="margin-t-3">
              <b>Payment Type</b>
              <div v-if="customerAccountPaymentType.value">
                {{ paymentTypeReferralDisplay }}
              </div>
              <div v-else>--</div>
            </div>
          </v-flex>
          <v-flex v-if="showDownPaymentPercent" xs6>
            <div class="margin-t-3">
              <b>Down Payment %</b>
              <div v-if="customerAccountDownPaymentPercent.value">
                {{ downPaymentPercentDisplay }}
              </div>
              <div v-else>--</div>
            </div>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs6 class="padding-r-2">
            <div class="margin-t-3">
              <b>Payment Terms</b>
              <div v-if="customerAccountPaymentTerms.value">
                {{ paymentTermsDisplay }}
              </div>
              <div v-else>--</div>
            </div>
          </v-flex>
          <v-flex xs6>
            <div class="margin-t-3">
              <b>Minimum Operator Quality</b>
              <div v-if="customerAccountMinimumOperatorQuality.value">
                {{ minimumOperatorQualityDisplay }}
              </div>
              <div v-else>--</div>
            </div>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs6 class="padding-r-2">
            <div class="margin-t-3">
              <b>Product</b>
              <div v-if="customerAccountProduct.value">
                {{ productDisplay }}
              </div>
              <div v-else>--</div>
            </div>
          </v-flex>
          <v-flex xs6>
            <div class="margin-t-3">
              <b>Ownership</b>
              <div v-if="customerAccountSourcingTeam.value">
                {{ sourcingTeamDisplay }}
              </div>
              <div v-else>--</div>
              <div v-if="customerAccountSupportTeam.value">
                {{ supportTeamDisplay }}
              </div>
              <div v-else>--</div>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout class="margin-t-4">
      <v-flex>
        <v-layout class="justify-center">
          <!-- Selected Operators -->
          <SelectedOperators
            style="width: 100%"
            :customerAccountId="customerAccountId"
          />
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import SelectedOperators from './SelectedOperators.vue'

export const PRICING_METHODS_MAP = {
  single_price: 'Single Price',
  bids: 'Bids',
  category: 'Category',
}

export default {
  components: {
    SelectedOperators,
  },
  computed: {
    customerAccount() {
      return this.$store.getters['customerAccounts/getCustomerAccountDetail']
    },
    customerAccountId() {
      return this.customerAccount?.id
    },
    customerAccountPricingMethod() {
      return {
        value: this.customerAccount?.effectiveDefaults?.pricingMethodKey
          ? PRICING_METHODS_MAP[
              this.customerAccount.effectiveDefaults.pricingMethodKey
            ]
          : null,
        inherited: !this.customerAccount?.defaults?.pricingMethodKey,
      }
    },
    customerAccountDiscountPercent() {
      return {
        value: this.customerAccount?.effectiveDefaults?.discountPercent,
        inherited: !this.customerAccount?.defaults?.discountPercent,
      }
    },
    customerAccountMarkupPercent() {
      return {
        value: this.customerAccount?.effectiveDefaults?.markupPercent,
        inherited: !this.customerAccount?.defaults?.markupPercent,
      }
    },
    customerAccountPaymentMethod() {
      return {
        value: this.customerAccount?.effectiveDefaults?.paymentMethodType
          ?.label,
        inherited: !this.customerAccount?.defaults?.paymentMethodType?.label,
      }
    },
    customerAccountNeedsManualReferral() {
      const value = this.customerAccount?.effectiveDefaults?.needsManualReferral
      return {
        value: value === true ? true : value === false ? false : null,
        inherited: this.customerAccount?.defaults?.needsManualReferral === null,
      }
    },
    customerAccountPaymentType() {
      return {
        value: this.customerAccount?.effectiveDefaults?.paymentType?.label,
        inherited: !this.customerAccount?.defaults?.paymentType?.label,
      }
    },
    customerAccountDownPaymentPercent() {
      return {
        value: this.customerAccount?.effectiveDefaults?.downPaymentPercent,
        inherited: !this.customerAccount?.defaults?.downPaymentPercent,
      }
    },
    customerAccountMinimumOperatorQuality() {
      return {
        value: this.customerAccount?.effectiveDefaults?.roundedMinQuality,
        inherited: !this.customerAccount?.defaults?.roundedMinQuality,
      }
    },
    customerAccountPaymentTerms() {
      return {
        value: this.customerAccount?.effectiveDefaults?.paymentTermsDays
          ? `Net ${this.customerAccount.effectiveDefaults.paymentTermsDays}`
          : null,
        inherited: !this.customerAccount?.defaults?.paymentTermsDays,
      }
    },
    customerAccountProduct() {
      return {
        value: this.customerAccount?.effectiveDefaults?.productClassification
          ?.label,
        inherited: !this.customerAccount?.defaults?.productClassification
          ?.label,
      }
    },
    customerAccountSourcingTeam() {
      return {
        value: this.customerAccount?.effectiveDefaults
          ?.sourcingTeamClassification?.label,
        inherited: !this.customerAccount?.defaults?.sourcingTeamClassification
          ?.label,
      }
    },
    customerAccountSupportTeam() {
      return {
        value: this.customerAccount?.effectiveDefaults
          ?.supportTeamClassification?.label,
        inherited: !this.customerAccount?.defaults?.supportTeamClassification
          ?.label,
      }
    },
    customerAccountAvatar() {
      return this.customerAccount?.lightLogoUrl
    },
    showDownPaymentPercent() {
      const defaultPaymentTypeKey = this.customerAccount?.effectiveDefaults
        ?.paymentType?.key
      return (
        defaultPaymentTypeKey === 'down_payment' ||
        defaultPaymentTypeKey === 'bill_after_services'
      )
    },
    pricingMethodDisplay() {
      let display = this.customerAccountPricingMethod.value
      if (this.customerAccountPricingMethod.inherited) {
        display += ' (Inherited)'
      }
      return display
    },
    discountPercentDisplay() {
      let display = this.customerAccountDiscountPercent.value
      if (this.customerAccountDiscountPercent.inherited) {
        display += ' (Inherited)'
      }
      return display
    },
    markupPercentDisplay() {
      let display = this.customerAccountMarkupPercent.value
      if (this.customerAccountMarkupPercent.inherited) {
        display += ' (Inherited)'
      }
      return display
    },
    paymentMethodDisplay() {
      let display = this.customerAccountPaymentMethod.value
      if (this.customerAccountPaymentMethod.inherited) {
        display += ' (Inherited)'
      }
      return display
    },
    needsManualReferralDisplay() {
      let display = this.customerAccountNeedsManualReferral.value ? 'No' : 'Yes'
      if (this.customerAccountNeedsManualReferral.inherited) {
        display += ' (Inherited)'
      }
      return display
    },
    paymentTypeReferralDisplay() {
      let display = this.customerAccountPaymentType.value
      if (this.customerAccountPaymentType.inherited) {
        display += ' (Inherited)'
      }
      return display
    },
    downPaymentPercentDisplay() {
      let display = this.customerAccountDownPaymentPercent.value
      if (this.customerAccountDownPaymentPercent.inherited) {
        display += ' (Inherited)'
      }
      return display
    },
    paymentTermsDisplay() {
      let display = this.customerAccountPaymentTerms.value
      if (this.customerAccountPaymentTerms.inherited) {
        display += ' (Inherited)'
      }
      return display
    },
    minimumOperatorQualityDisplay() {
      let display = this.customerAccountMinimumOperatorQuality.value
      if (this.customerAccountMinimumOperatorQuality.inherited) {
        display += ' (Inherited)'
      }
      return display
    },
    productDisplay() {
      let display = this.customerAccountProduct.value
      if (this.customerAccountProduct.inherited) {
        display += ' (Inherited)'
      }
      return display
    },
    sourcingTeamDisplay() {
      let display = this.customerAccountSourcingTeam.value
      if (this.customerAccountSourcingTeam.inherited) {
        display += ' (Inherited)'
      }
      return display
    },
    supportTeamDisplay() {
      let display = this.customerAccountSupportTeam.value
      if (this.customerAccountSupportTeam.inherited) {
        display += ' (Inherited)'
      }
      return display
    },
  },
  methods: {
    openDetailSidebar() {
      const component = () =>
        import('./CustomerAccountDefaultSettingsSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          title: 'Edit Configuration',
          id: this.$route.params.id,
        },
        component,
        persistent: true,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.default-settings-section {
  border: 2px solid $gray-light;
  border-radius: 10px;
}
</style>
