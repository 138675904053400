<template>
  <div>
    <v-tabs v-model="selectedTab" class="mb-4">
      <template v-for="tab in tabs">
        <v-tab
          :id="`customer-accounts-rider-settings-chaperones-tab-${tab.text.toLowerCase()}`"
          :key="`customer-accounts-rider-settings-chaperones-tab-${tab.text.toLowerCase()}`"
        >
          {{ tab.text }}
        </v-tab>
      </template>
    </v-tabs>
    <CustomerAccountRiderSettings
      v-show="viewRiderSettings"
      style="margin-left: 15px"
    />
    <CustomerAccountChaperonesList
      v-show="viewChaperones"
      :customer-account-id="customerAccountId"
      style="margin-left: 15px"
    />
  </div>
</template>

<script>
import CustomerAccountRiderSettings from './CustomerAccountRiderSettings.vue'
import CustomerAccountChaperonesList from './CustomerAccountChaperonesList.vue'

const RIDER_SETTINGS_TAB = 0
const CHAPERONES_TAB = 1

export default {
  components: {
    CustomerAccountRiderSettings,
    CustomerAccountChaperonesList,
  },
  data() {
    return {
      selectedTab: 0,
      tabs: [
        {
          _t_id: '697974b0-2a3d-430f-877b-918d0dfbdd6d',
          text: 'Rider Settings',
          action: () => {
            this.viewRiderSettings = true
            this.viewChaperones = false
          },
        },
        {
          _t_id: 'ee9ad835-7b46-4411-9cb6-1dcfdd55c894',
          text: 'Chaperones',
          action: () => {
            this.viewRiderSettings = false
            this.viewChaperones = true
          },
        },
      ],
    }
  },
  computed: {
    customerAccount() {
      return this.$store.getters['customerAccounts/getCustomerAccountDetail']
    },
    customerAccountId() {
      return this.customerAccount?.id
    },
    viewRiderSettings() {
      return this.selectedTab == RIDER_SETTINGS_TAB
    },
    viewChaperones() {
      return this.selectedTab == CHAPERONES_TAB
    },
  },
  methods: {
    isTabActive(tab) {
      if (tab._t_id === '697974b0-2a3d-430f-877b-918d0dfbdd6d') {
        return this.viewRiderSettings
      }
      if (tab._t_id === 'ee9ad835-7b46-4411-9cb6-1dcfdd55c894') {
        return this.viewChaperones
      }
      return false
    },
  },
}
</script>
