<template>
  <div>
    <v-btn
      v-if="!rowProps.item.signedUp && !inviteSent"
      flat
      color="primary"
      id="customer-account-user-invite-user"
      @click="inviteUser"
    >
      Resend Invitation
    </v-btn>
    <v-btn
      v-else-if="inviteSent"
      flat
      color="primary"
      id="customer-account-user-invite-user"
      disabled
    >
      Invitation Sent
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import customerAccounts from '@/services/customerAccounts'

export default {
  data() {
    return {
      inviteSent: false,
    }
  },
  props: {
    rowProps: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
    }),
    inviteUser() {
      customerAccounts.resendCustomerAccountInvite({
        email: this.rowProps.item.email,
      })
      this.inviteSent = true
      this.showAlert({
        message: 'Invitation Sent Successfully.',
        type: 'success',
      })
    },
  },
}
</script>
