import Vue from 'vue'
import router from '@/router/index'
import { mapActions } from 'vuex'
import { baseUrl, environmentPrefix } from '@/utils/env'
import { authComputed } from '@/state/helpers'
import auth from '@/services/auth'

const MenuDataMapper = function (
  row,
  rowProps,
  handleAction,
  getV2Quote,
  duplicateQuote,
  viewPDF,
  copyPDFUrl,
  openCRMActions,
  openCheckoutPage,
  canOpenCharterUpQuote,
  isAdmin
) {
  const QUOTE_TYPE_STANDARD = 0
  const QUOTE_TYPE_CONTRACT = 1

  const menuOut = []

  if (!row.isConverted && row.quoteType === QUOTE_TYPE_STANDARD) {
    menuOut.push({
      icon: () => 'edit',
      title: () => 'Edit Quote',
      id: 'edit',
      quickAccess: true,
      path: () => {
        return `/quotes/edit/${row.quoteId}`
      },
      width: 24,
      height: 24,
      viewBox: '0 0 24 24',
    })
    if (
      !(row.paymentTypes || '').toLowerCase().includes('bill after services') &&
      row.checkoutPage &&
      row.checkoutPage?.id !== 4 &&
      row.pricingMethod === 'single_price'
    ) {
      menuOut.push({
        icon: () => 'credit_card',
        title: () => 'Manual Checkout',
        id: 'credit-card',
        quickAccess: true,
        target: '_blank',
        href: () => {
          return `${row.checkoutPage.domain}/checkout/${row.hash}`
        },
      })
    } else if (
      !(row.paymentTypes || '').toLowerCase().includes('bill after services')
    ) {
      menuOut.push({
        icon: () => 'credit_card',
        title: () => 'Manual Checkout',
        id: 'credit-card',
        target: '_blank',
        quickAccess: true,
        click: (row) => {
          openCheckoutPage(row)
        },
      })
    }
    menuOut.push({
      icon: () => 'trash',
      id: 'delete',
      title: () => 'Delete Quote',
      quickAccess: true,
      click: (() => () => {
        handleAction('DELETE_QUOTE', rowProps)
      })(),
    })
  }

  if (isAdmin && !(!row.isConverted && row.quoteType === QUOTE_TYPE_STANDARD)) {
    menuOut.push({
      icon: () => 'trash',
      id: 'delete',
      title: () => 'Delete Quote',
      quickAccess: false,
      click: (() => () => {
        handleAction('DELETE_QUOTE', rowProps)
      })(),
    })
  }

  if (!row.isConverted && row.quoteType === QUOTE_TYPE_CONTRACT) {
    menuOut.push({
      icon: () => 'edit',
      title: () => 'Edit Contract',
      viewBox: '0 0 24 24',
      id: 'edit',
      quickAccess: true,
      path: () => {
        return `/contracts/edit/${row.quoteId}`
      },
    })

    menuOut.push({
      icon: () => 'credit_card',
      title: () => 'Manual Checkout',
      id: 'credit-card',
      quickAccess: true,
      target: '_blank',
      href: () => {
        return `${row.checkoutPage?.domain || ''}/ci/checkout/contract/${
          row.hash
        }`
      },
    })
  }
  menuOut.push({
    icon: () => 'edit_note',
    id: 'edit-note',
    title: () => 'CRM Actions',
    click: () => {
      openCRMActions()
    },
    viewBox: '0 0 24 24',
  })

  menuOut.push({
    icon: () => 'file_pdf',
    id: 'pdf',
    title: () => 'View PDF',
    click: async () => {
      await viewPDF()
    },
    viewBox: '0 0 34 34',
  })

  menuOut.push({
    icon: () => 'link',
    title: () => 'Copy PDF URL',
    click: async () => {
      await copyPDFUrl()
    },
    viewBox: '0 0 24 24',
  })
  if (row.quoteType === 0) {
    menuOut.push({
      icon: () => 'copy',
      id: 'duplicate',
      title: () => 'Duplicate Quote',
      click: () => {
        router.push({ path: `/quotes/add?d=${rowProps?.item?.quoteId}` })
      },
    })
  }
  if (!row.soldOut && !row.isConverted) {
    menuOut.push({
      icon: () => 'clear',
      id: 'sold-out',
      title: () => 'Mark as Sold Out',
      click: () => {
        handleAction('QUOTE_SOLD_OUT', rowProps)
      },
      viewBox: '0 0 24 24',
    })
  }
  if (!row.isConverted) {
    menuOut.push({
      icon: () => 'error_color',
      id: 'sold-out',
      title: () =>
        row.isPendingConfirmation
          ? 'Remove Pending Confirmation'
          : 'Mark Pending Confirmation',
      click: () => {
        handleAction('QUOTE_PENDING_CONFIRMATION', rowProps)
      },
      viewBox: '0 0 24 24',
    })
  }

  return menuOut
}
// collectionSingular
export default function (component) {
  return Vue.component('WrappedActions', {
    props: {
      row: {
        type: Object,
        default: () => ({}),
      },
      handleAction: {
        type: Function,
        default: () => null,
      },
      rowProps: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      ...authComputed,
      canOpenCharterUpQuote() {
        const roles = this.currentUserProfile?.roles || []
        const charterUpRole = roles.find(
          (r) => r.roleName === 'is_charterup_sales'
        )
        const charterUpPermissions = charterUpRole?.permissions || []
        return !!charterUpPermissions.find(
          (p) => p.permissionName === 'canSendCharterUPQuote'
        )
      },
      isAdmin() {
        const roles = this.currentUserProfile?.roles || []
        return !!roles.find(
          (r) =>
            r.roleName === 'is_free_admin' ||
            'is_paid_admin' ||
            'is_broker_admin' ||
            'is_admin_admin'
        )
      },
      canLoginAsCustomer() {
        const roles = this.currentUserProfile?.roles || []
        const role = roles.find((r) => r.roleName === 'can_login_as_customer')
        return !!role
      },
    },
    methods: {
      ...mapActions({
        showAlert: 'app/showAlert',
        duplicateQuote: 'quotes/duplicateQuote',
        getV2Quote: 'quotes/getV2Quote',
      }),
      async pdfUrl() {
        if (!(this.quote && this.quote.hash)) {
          const originalQuoteData = await this.getV2Quote(
            this.rowProps?.item?.quoteId
          )
          this.quote = originalQuoteData?.data?.quote
        }
        const pdfHost = baseUrl('pdf')
        const { updatedOn, hash } = this.quote
        const timestamp = new Date(updatedOn).getTime()
        if (this.quote.quoteType !== 0) {
          return `https://${pdfHost}/pdf/contract-quote-${hash}.pdf`
        }
        return `https://${pdfHost}/pdf/quote-${hash}-${timestamp}.pdf`
      },
      async viewPDF() {
        // FIXME: this is causing a popup complaint from browser
        const url = await this.pdfUrl()
        window.open(url, '_blank')
      },
      async copyPDFUrl() {
        const url = await this.pdfUrl()
        navigator.clipboard.writeText(url)
        this.showAlert({
          message: 'PDF URL copied to clipboard.',
        })
      },
      openCRMActions() {
        const component = () =>
          import('@/components/QuoteFormCRMActionsSidebar.vue')
        this.$store.dispatch('app/openSidebarDialog', {
          data: {
            title: 'CRM Actions',
            quoteId: this.row.quoteId,
          },
          component,
        })
      },
      async loginAsCustomer() {
        const payload = {
          userId: this.rowProps.item.customerId,
          delegatorId: this.currentUser.userId,
        }
        try {
          const res = await auth.requestJwtLogin(payload)
          const redirectUrl = `https://${environmentPrefix()}.charterup.com/quotes/${
            this.row.quoteId
          }?jwt=${res.data.token}`
          window.open(redirectUrl, '_blank')
        } catch (e) {
          console.error(e)
        }
      },
      openCheckoutPage() {
        if (this.canLoginAsCustomer) {
          this.loginAsCustomer()
        } else if (this.row.isCharterUpQuote) {
          router.push({
            name: 'quotes.view',
            params: {
              id: this.row.quoteId,
              subRoute: 'bids',
            },
          })
        } else {
          const url = `https://${environmentPrefix()}.charterup.com/guest-checkout/${
            this.row.hash
          }`
          window.open(url, '_blank')
        }
      },
    },
    render(createElement) {
      return createElement(component, {
        props: {
          row: this.row,
          handleAction: this.handleAction,
          rowProps: this.rowProps,
          menu: new MenuDataMapper(
            this.row,
            this.rowProps,
            this.handleAction,
            this.getV2Quote,
            this.duplicateQuote,
            this.viewPDF,
            this.copyPDFUrl,
            this.openCRMActions,
            this.openCheckoutPage,
            this.canOpenCharterUpQuote,
            this.isAdmin
          ),
        },
      })
    },
  })
}
