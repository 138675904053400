<template>
  <v-container class="base-table-padding-left-none">
    <v-layout row align-center>
      <v-checkbox
        id="customer-account-rider-settings-has-rider-tracking"
        v-model="model.hasRiderTracking"
        class="shrink mt-0"
        solo
        hide-details
      />
      <v-flex shrink>Has Rider Tracking</v-flex>
      <v-tooltip right light>
      <template v-slot:activator="{ on }">
        <v-icon class="mx-2" color="primary" dark v-on="on">info</v-icon>
      </template>
      <span light>
        This setting enables the Rider Tracking feature for this customer account.
        <br />
        In Coachrail, contracts associated to this customer account will have the ability to configure rider tracking.
        <br />
        In Charterup-Ci, the admin users will be able to view rider data.
      </span>
      </v-tooltip>
    </v-layout>
    <div class="ml-3">
      <v-layout row align-center>
        <v-checkbox
          id="customer-account-rider-settings-has-rider-groups"
          v-model="model.hasRiderGroups"
          class="shrink mt-0"
          solo
          hide-details
        />
        <v-flex shrink>Has Rider Groups</v-flex>
      <v-tooltip right>
      <template v-slot:activator="{ on }">
        <v-icon class="mx-2" color="primary" dark v-on="on">info</v-icon>
      </template>
        <span>
          This setting enables the Rider Groups to be visible to the customer.
        </span>
      </v-tooltip>
      </v-layout>
      <v-layout row align-center>
        <v-checkbox
          id="customer-account-rider-settings-has-rider-groups"
          v-model="model.hasRequireRiderGroups"
          class="shrink mt-0"
          solo
          hide-details
        />
        <v-flex shrink>Require Rider Groups</v-flex>
      <v-tooltip right>
      <template v-slot:activator="{ on }">
        <v-icon class="mx-2" color="primary" dark v-on="on">info</v-icon>
      </template>
        <span>
          This setting requires rider groups to be configured before riders can reserve seats.
        </span>
      </v-tooltip>
      </v-layout>
      <v-layout row align-center>
        <v-checkbox
          id="customer-account-rider-settings-has-rider-capacity-limits"
          v-model="model.hasRiderCapacityLimits"
          class="shrink mt-0"
          solo
          hide-details
        />
        <v-flex shrink>Has Rider Capacity Limits</v-flex>
      <v-tooltip right>
      <template v-slot:activator="{ on }">
        <v-icon class="mx-2" color="primary" dark v-on="on">info</v-icon>
      </template>
        <span>
          This setting enforces rider capacity limits for riders when creating a ticket.
          <br/> Capacity limits are determined by the listed passenger count on assigned vehicles, or
          <br/> a default capacity limit by vehicle type if no vehicle is assigned.
        </span>
        </v-tooltip>
      </v-layout>
      <v-layout
        v-if="isRiderIdTicketsEnabled"
        row
        align-center
      >
        <v-checkbox
          id="customer-account-rider-settings-has-rider-id-tickets"
          v-model="model.hasRiderIdTickets"
          class="shrink mt-0"
          solo
          hide-details
        />
        <v-flex shrink>Has Rider ID Tickets</v-flex>
      <v-tooltip right>
      <template v-slot:activator="{ on }">
        <v-icon class="mx-2" color="primary" dark v-on="on">info</v-icon>
      </template>
        <span>
          This setting enables the customer to download rider qr codes from the customer site.
        </span>
        </v-tooltip>
      </v-layout>
    </div>
    <v-btn
      @click="save"
      color="primary"
      class="mt-3"
      :disabled="formUnchanged || loading"
      :loading="loading"
    >
    Save
    </v-btn>
      <v-btn
        @click="resetForm"
        class="mt-3 ml-3 btn-secondaryaction"
        :disabled="formUnchanged || loading"
      >
      Reset
    </v-btn>
    <v-divider class="mt-3"></v-divider>
    <div class="mt-2">
      <CRInput
        id="customer-account-rider-settings-static-site-url"
        :input-value="customerAccountStaticSiteUrl"
        style="width: 100%"
        label="Static Site URL"
        disabled
      />
    </div>
  </v-container>
</template>

<script>
import { EventBus } from '@/utils/event-bus'

export default {
  data: () => ({
    model: {
      hasRiderTracking: false,
      hasRiderGroups: false,
      hasRequireRiderGroups: false,
      hasRiderCapacityLimits: false,
      hasRiderIdTickets: false,
    },
    loading: false,
  }),

  mounted() {
    this.resetForm()
  },
  computed: {
    customerAccount() {
      return this.$store.getters['customerAccounts/getCustomerAccountDetail']
    },
    isRiderIdTicketsEnabled() {
      const { getters = {} } = this.$store
      return getters['featureToggles/isRiderIdTicketsEnabled']
    },
    customerAccountHasRiderTracking() {
      return this.customerAccount?.hasRiderTracking
    },
    customerAccountHasRiderGroups() {
      return this.customerAccount?.hasRiderGroups
    },
    customerAccountHasRequireRiderGroups() {
      return this.customerAccount?.hasRequireRiderGroups
    },
    customerAccountHasRiderCapacityLimits() {
      return this.customerAccount?.hasRiderCapacityLimits
    },
    customerAccountHasRiderIdTickets() {
      return this.customerAccount?.hasRiderIdTickets
    },
    customerAccountStaticSiteUrl() {
      return this.customerAccount?.staticSiteUrl
    },

    customerAccountRiderSettings() {
      return {
        hasRiderTracking: this.customerAccountHasRiderTracking,
        hasRiderGroups: this.customerAccountHasRiderGroups,
        hasRequireRiderGroups: this.customerAccountHasRequireRiderGroups,
        hasRiderCapacityLimits: this.customerAccountHasRiderCapacityLimits,
        hasRiderIdTickets: this.customerAccountHasRiderIdTickets,
      }
    },

    formUnchanged() {
      return JSON.stringify(this.model) === JSON.stringify(this.customerAccountRiderSettings)
    },
  },
  methods: {
    async save() {
      this.loading = true

      try {
        await this.$store.dispatch('customerAccounts/updateCustomerAccount', {
          id: this.customerAccount.id,
          ...this.model,
        })
      } catch (error) {
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: 'Failed to save customer account rider settings',
        })
      }

      EventBus.$emit('load-customer-account')

      this.$nextTick(() => {
        this.$store.dispatch('app/showAlert', {
          type: 'success',
          message: 'Customer account rider settings saved',
        })
        this.loading = false
      })
    },

    resetForm() {
      this.model = { ...this.customerAccountRiderSettings }
    },
  }
}
</script>
