<template>
  <div>
    <BaseTableWrapper
      id="team-accounts-list"
      :table-init="tableInit"
      :initial-filters="initialFilters"
      :remove-padding="true"
      add-new-button-label="Connect Team"
      class="base-table-padding-left-none"
    >
      <h1 class="page-header"></h1>
    </BaseTableWrapper>
  </div>
</template>

<script>
import customerAccounts from '@/services/customerAccounts'
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import { phoneFormatFilter } from '@/utils/phone'
import { EventBus } from '@/utils/event-bus'
import teamAccountsActionsWarapper from './TeamAccountsActionsWrapper.js'
import CustomerAccountsTableActions from '@/components/CustomerAccountsTableActions.vue'

export default {
  components: {
    BaseTableWrapper,
  },
  data() {
    return {
      filters: () => [],
      sorts: () => [],
      isAdmin: false,
      showImportDialog: false,
      initialFilters: [],
      columns: [],
      tableInit: {
        addNewEnabled: true,
        enableStatusFilterToggle: false,
        enableSavedViews: false,
        externalShareFilters: this.receiveFilters,
        externalShareSorts: this.receiveSorts,
        importTable: this.importClicked.bind(this),
        addNewHandler: () => {
          return this.$store.dispatch('app/openDialog', {
            component: () => import('@/components/AddTeamAccountForm.vue'),
            data: {
              enterpriseAccountId: this.customerAccount.id,
            },
          })
        },
        detailKeyId: 'customerAccountId',
        tableId: 'customer_accounts_tv_view',
        currentPage: 1,
        perPage: 10,
        forceRefresh: 0,
        isDetailed: false,
        initialFilters: ['enterpriseAccount/customerAccountId'],
        columns: [],
        collection: 'CustomerAccounts',
        action: (params) => customerAccounts.getCustomerAccounts(params),
      },
    }
  },
  computed: {
    customerAccount() {
      return this.$store.getters['customerAccounts/getCustomerAccountDetail']
    },
  },
  async mounted() {
    await this.getColumns()
    const parentAccountFilter = {
      column: this.columns.find(
        (column) => column._t_id === 'parentAccountFilter'
      ),
      value: this.customerAccount.id,
      hideOnFilterBar: true,
    }
    this.initialFilters.push(parentAccountFilter)
    this.tableInit.columns.push(...this.columns)

    EventBus.$on('global-dialog-closed-action', () => {
      this.refresh()
    })
  },
  unmounted() {
    EventBus.$off('global-dialog-closed-action')
  },
  methods: {
    async getColumns() {
      const customerAccountsWrappedActions = await teamAccountsActionsWarapper(
        CustomerAccountsTableActions
      )

      this.columns = [
        {
          _t_id: 'x614fbb01',
          prop: '/',
          text: 'Actions',
          component: customerAccountsWrappedActions,
          sort: false,
          filter: false,
          detail: false,
          type: 'actions',
        },
        {
          _t_id: '51cf0662',
          prop: 'name',
          text: 'Customer Account Name',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
          sortProp: 'name',
        },
        {
          _t_id: '51cf0d6a',
          prop: ['address/street1', 'address/street2'],
          text: 'Address',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
          sortProp: 'address/street1',
        },
        {
          _t_id: '51cf07ca',
          prop: 'email',
          text: 'Email',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
          sortProp: 'email',
        },
        {
          _t_id: '51cf090a',
          prop: 'phone',
          text: 'Phone',
          sort: true,
          filter: true,
          type: 'text',
          computedText: (item) => phoneFormatFilter(item),
          filterType: 'contains',
          sortProp: 'phone',
        },
        {
          _t_id: '51cf0a4a',
          prop: 'industry/label',
          text: 'Industry',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
          sortProp: 'industry/label',
        },
        {
          _t_id: '51cf0fe0',
          prop: 'enterpriseAccount/name',
          text: 'Enterprise Account',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
          sortProp: 'company/name',
        },
        {
          _t_id: 'parentAccountFilter',
          filterType: 'eq',
          prop: 'enterpriseAccount/customerAccountId',
          type: 'number',
          filterPlaceholder: true,
        },
      ]
    },
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    importCancelled() {
      this.showImportDialog = false
    },
    importClicked() {
      this.showImportDialog = false
      this.$nextTick(() => {
        this.showImportDialog = true
      })
    },
    importComplete() {
      this.showImportDialog = false
      this.tableInit.forceRefresh = 1 + Math.random()
    },
    refresh() {
      EventBus.$emit('global-table-view-refresh')
    },
  },
}
</script>
