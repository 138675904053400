<template>
  <v-layout class="action-links" row>
    <div
      v-for="(item, idx) in menu.filter((m) => m.quickAccess)"
      :key="idx"
      class="action-link-container"
    >
      <v-tooltip top>
        <template #activator="action">
          <span v-on="action.on">
            <CRIcon
              class="cursor-pointer"
              :class="item.icon()"
              :view-box="item.viewBox"
              :color="item.color ? item.color() : 'primary'"
              @click.native="handleClick(item)"
            >
              {{ item.icon() }}
            </CRIcon>
          </span>
        </template>
        <span>{{ item.title() }}</span>
      </v-tooltip>
    </div>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    handleAction: {
      type: Function,
      default: () => null,
    },
    rowProps: {
      type: Object,
      default: () => ({}),
    },
    menu: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  methods: {
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    async handleClick(item) {
      item.action()
    },
  },
}
</script>

<style lang="scss" scoped>
.action-links {
  height: 22px;
}

.action-link,
.action-links a {
  display: flex;
  align-items: center;
}

.action-link-container {
  height: 22px;
  margin-right: 3px;
}
</style>
