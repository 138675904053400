<template>
  <v-data-table
    :headers="headers"
    :items="quotes"
    :pagination.sync="pagination"
    :loading="loading"
    :totalItems="totalItems"
    disable-initial-sort
  >
    <template #items="props">
      <td>
        <QuotesTableActions
          :row="{
            ...props.item,
            quoteType: 0,
          }"
          :row-props="props"
        />
      </td>
      <td>
        <a
          @click="
            $router.push({
              name: 'quotes.view',
              params: { id: props.item.quoteId },
            })
          "
        >
          {{ props.item.quoteId }}
        </a>
      </td>
      <td>{{ amount(props.item) }}</td>
      <td>{{ createdOn(props.item) }}</td>
      <td>{{ pickupDate(props.item) }}</td>
      <td>{{ formatQuotePickupDestinationText(props.item) }}</td>
      <td>{{ props.item.customerName }}</td>
      <td>{{ props.item.customerAccountName }}</td>
      <td>{{ expiration(props.item) }}</td>
      <td>{{ convertedOn(props.item) }}</td>
    </template>
  </v-data-table>
</template>

<script>
import { phoneFormatFilter } from '@/utils/phone'
import {
  currencyFilter,
  expirationDelta,
  formatQuotePickupDestinationText,
} from '@/utils/string'
import { DateTime } from 'luxon'
import { quotesCustomerAccountTableView } from '@/services/charterup/quotes'
import { sort } from '@/utils/sort'
import QuotesTableActions from '@/components/QuotesTableActions.vue'

import quotesActionsWrapper from '@/components/QuotesActionsWrapper'

const wrappedActions = quotesActionsWrapper(QuotesTableActions)

export default {
  components: { QuotesTableActions: wrappedActions },
  data() {
    return {
      pagination: {
        page: 1,
        rowsPerPage: 10,
      },
      totalItems: 0,
      loading: false,
      formatQuotePickupDestinationText,
      quotes: [],
      headers: [
        { text: '', align: 'left', sortable: false },
        { text: 'ID', align: 'left', value: 'quoteId', sortable: true },
        { text: 'Amount', align: 'left', sortable: false },
        {
          text: 'Quoted On',
          align: 'left',
          value: 'createdOn',
          sortable: true,
        },
        {
          text: 'Pickup Date',
          align: 'left',
          value: 'pickupDate',
          sortable: true,
        },
        { text: 'Pickup/Dropoff', align: 'left', sortable: false },
        {
          text: 'Booking Contact',
          align: 'left',
          value: 'customerName',
          sortable: true,
        },
        {
          text: 'Team',
          align: 'left',
          value: 'customerAccountName',
          sortable: true,
        },
        { text: 'Expiration', align: 'left', sortable: false },
        {
          text: 'Converted On',
          align: 'left',
          value: 'convertedOn',
          sortable: true,
        },
      ],
    }
  },
  props: {
    customerAccountId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.fetchQuotesList()
  },
  watch: {
    pagination: {
      handler() {
        this.fetchQuotesList()
      },
      deep: true,
    },
  },
  methods: {
    convertedOn(quote) {
      if (quote.convertedOn === null) {
        return ''
      }

      return DateTime.fromISO(quote.convertedOn, {
        zone: quote.timeZone,
      }).toLocaleString(DateTime.DATE_SHORT)
    },
    amount(quote) {
      return currencyFilter(quote.amount)
    },
    isExpired(quote) {
      const expiration = quote?.expirationDate
      if (!expiration) {
        return true
      }
      const now = new Date()
      const thenDate = new Date(`${expiration.split('+')[0]}Z`)
      return now.getTime() - thenDate.getTime() > 0
    },
    createdOn(quote) {
      return DateTime.fromISO(quote.createdOn, {
        zone: quote.timeZone,
      }).toLocaleString(DateTime.DATE_SHORT)
    },
    pickupDate(quote) {
      const firstPickup = quote.trips?.[0]?.stops?.find(
        (stop) => stop.orderIndex === 0
      )?.pickupDate
      if (!firstPickup?.pickupDate) {
        return ''
      }
      return DateTime.fromISO(firstPickup.pickupDate, {
        zone: firstPickup.address.timeZone,
      }).toLocaleString(DateTime.DATE_SHORT)
    },
    pickupDropoff(quote) {
      const stops = quote.trips?.[0]?.stops
      const firstPickup = stops.find((stop) => stop.orderIndex === 0)
      const firstDropoff = stops.find((stop) => stop.orderIndex === 1)
      return this.formatPickupDestinationTextFromAddresses(
        firstPickup.address,
        firstDropoff.address,
        '>'
      )
    },
    formatPickupDestinationTextFromAddresses(pickup, dropoff, separator) {
      return `${pickup.addressName} ${separator} ${dropoff.addressName}`
    },
    expiration(quote) {
      const expiration = quote.expirationDate

      if (quote.convertedOn) {
        return 'Converted'
      }

      if (!expiration) {
        if (!quote.isCharterUpQuote) {
          return ''
        }
        return 'Invalid Date'
      }
      const now = new Date()
      const thenDate = new Date(`${expiration.split('+')[0]}Z`)
      return now.getTime() - thenDate.getTime() < 0
        ? expirationDelta(expiration)
        : 'Expired'
    },
    async fetchQuotesList() {
      const { sortBy, descending, page, rowsPerPage } = this.pagination

      let params = {
        customerAccountId: this.customerAccountId,
        page,
        pageSize: rowsPerPage,
      }

      if (sortBy !== null) {
        // add sort
        const quoteSort = sort()

        quoteSort.add({
          prop: sortBy,
          direction: descending ? 'desc' : 'asc',
        })
        params = {
          ...params,
          sorts: quoteSort.asQueryParams(),
        }
      }

      this.loading = true
      const resp = await quotesCustomerAccountTableView(params)
      this.loading = false

      if (!resp.data?.resultList) {
        this.quotes = []
        this.pagination.page = 1
        return
      }

      this.quotes = resp.data.resultList
      this.totalItems = resp.data.count
    },
  },
}
</script>
