import { render, staticRenderFns } from "./CustomerAccountSummary.vue?vue&type=template&id=3b86de46&scoped=true"
import script from "./CustomerAccountSummary.vue?vue&type=script&lang=ts"
export * from "./CustomerAccountSummary.vue?vue&type=script&lang=ts"
import style0 from "./CustomerAccountSummary.vue?vue&type=style&index=0&id=3b86de46&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b86de46",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VAvatar,VCheckbox,VFlex,VIcon,VLayout,VTab,VTabs})
