<template>
  <v-dialog v-model="deleteDialog" max-width="500px">
    <template #activator="{ on }">
      <v-icon
        :id="`chaperonesListDelete-${chaperone.externalId}`"
        small
        class="mx-1"
        v-on="on"
      >
        delete
      </v-icon>
    </template>
    <v-card>
      <v-card-title class="subheading" primary-title>
        Are you sure you want to delete this chaperone?
        <span>{{ chaperone.email }}</span>
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :id="`chaperonesListDeleteConfirm-${chaperone.externalId}`"
          color="primary"
          @click="deleteItem(chaperone)"
        >
          delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    chaperone: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      deleteDialog: false,
    }
  },
  methods: {
    deleteItem(chaperone) {
      this.$store.dispatch('chaperones/deleteChaperone', chaperone)
      this.deleteDialog = false
    },
  },
}
</script>
