<template>
  <v-container fluid class="base-table-padding-left-none">
    <div class="d-flex justify-end">
      <v-btn
        flat
        class="background-primary text-white max-w-80"
        @click="openActionsSidebar('add')"
      >
        Add New
      </v-btn>
    </div>
    <v-data-table
      id="riderGroupsTable"
      :loading="loading"
      :headers="headers"
      :items="riderGroups"
      item-key="externalId"
      :pagination.sync="pagination"
    >
      <template #items="props">
        <td class="text-xs">
          <div class="d-flex align-center">
            {{ props.item.name }}
            <div class="margin-l-2">
              <CRTag
                v-if="props.item.isDefaultGroup"
                label="Default"
                color="blue"
              />
            </div>
          </div>
        </td>
        <td class="text-xs">
          <router-link
            class="text-decoration-none"
            target="_blank"
            :to="{
              name: 'customer-accounts.view',
              params: { id: props.item.customerAccount.id },
            }"
          >
            {{ props.item.customerAccount.name }}
          </router-link>
        </td>
        <td class="text-xs">
          <div v-for="contract in props.item.contracts" :key="contract.id">
            <router-link
              class="text-decoration-none"
              target="_blank"
              :to="{
                name: 'contracts.view',
                params: { id: contract.id },
              }"
            >
              {{ contract.name }}
            </router-link>
          </div>
        </td>
        <td class="text-xs">
          <div v-for="domain in props.item.domains" :key="domain">
            {{ domain }}
          </div>
        </td>
        <td class="text-xs">{{ formatDate(props.item.createdOn) }}</td>
        <td class="text-xs">{{ props.item.numRiders }}</td>
        <td>
          <v-menu>
            <template #activator="{ on }">
              <v-icon small class="mx-1" v-on="on">more_vert</v-icon>
            </template>
            <v-list>
              <v-list-tile @click="openActionsSidebar('edit', props.item)">
                <v-list-tile-title>Edit Rider Group</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                v-if="!props.item.isDefaultGroup"
                @click="openActionsSidebar('delete', props.item)"
              >
                <v-list-tile-title>Delete Rider Group</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import customerAccounts from '@/services/customerAccounts'
import { DateTime } from 'luxon'
import { EventBus } from '@/utils/event-bus'
import { title } from 'process'

export default {
  data() {
    return {
      loading: false,
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 10,
        sortBy: 'name',
        totalItems: 0,
      },
      headers: [
        {
          align: 'left',
          text: 'Name',
          value: 'name',
        },
        {
          align: 'left',
          text: 'Customer Account',
          value: 'customerAccount.name',
        },
        {
          align: 'left',
          text: 'Contracts',
          value: 'contracts.name',
        },
        {
          align: 'left',
          text: 'Domains',
          value: 'domains',
        },
        {
          align: 'left',
          text: 'Created On',
          value: 'createdOn',
        },
        {
          align: 'left',
          text: 'Rider Count',
          value: 'numRiders',
        },
        {
          text: 'Actions',
          value: 'userId',
          sortable: false,
        },
      ],
      riderGroups: [],
      contracts: [],
    }
  },
  computed: {
    customerAccount() {
      return this.$store.getters['customerAccounts/getCustomerAccountDetail']
    },
  },
  async mounted() {
    this.getTable()
    EventBus.$on('global-table-view-refresh', () => {
      this.getTable()
    })
  },
  methods: {
    async getTable() {
      this.loading = true
      try {
        const riderGroupResponse = await customerAccounts.getRiderGroups(
          this.customerAccount.id
        )
        const contractsResponse = await customerAccounts.getContracts(
          this.customerAccount.id
        )
        this.riderGroups = riderGroupResponse.data
        this.contracts = contractsResponse.data
      } catch (error) {
        console.error(error)
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: 'Error fetching rider groups',
        })
        this.loading = false
      }

      this.loading = false
    },
    formatDate(date) {
      return !!date
        ? DateTime.fromISO(date, {
            zone: 'local',
          }).toFormat('MM/dd/yyyy t ZZZZ')
        : ''
    },
    openActionsSidebar(mode, riderGroup = null) {
      let title = ''
      switch (mode) {
        case 'add':
          title = 'Add Rider Group'
          break
        case 'edit':
          title = 'Edit Rider Group'
          break
        case 'delete':
          title = 'Delete Rider Group'
          break
      }
      const component = () => import('./CustomerAccountRiderGroupSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          customerAccountId:
            riderGroup?.customerAccount?.id || this.customerAccount.id,
          customerAccountName:
            riderGroup?.customerAccount?.name || this.customerAccount.name,
          mode: mode,
          riderGroup: riderGroup,
          title: title,
        },
        persistent: true,
        component,
      })
    },
  },
}
</script>
