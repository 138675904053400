<template>
  <v-container fluid class="base-table-padding-left-none">
    <v-data-table
      id="sharedContactsTable"
      :loading="loading"
      :headers="headers"
      :items="sharedContacts"
      item-key="userId"
      :pagination.sync="pagination"
    >
      <template #items="props">
        <td class="text-xs">{{ props.item.email }}</td>
        <td class="text-xs">
          {{ props.item.firstName }} {{ props.item.lastName }}
        </td>

        <td class="text-xs">{{ formatDate(props.item.lastLogin) }}</td>
        <td
          class="text-xs text-primary cursor-pointer"
          @click="openSharedTripsSidebar(props.item)"
        >
          {{ props.item.quoteIds.length + props.item.reservationIds.length }}
        </td>
        <td>
          <v-menu>
            <template #activator="{ on }">
              <v-icon small class="mx-1" v-on="on">more_vert</v-icon>
            </template>
            <v-list>
              <v-list-tile @click="openActionsSidebar(props.item, false)">
                <v-list-tile-title>Share All</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click="openActionsSidebar(props.item, true)">
                <v-list-tile-title>Unshare All</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import customerAccounts from '@/services/customerAccounts'
import { DateTime } from 'luxon'
import { EventBus } from '@/utils/event-bus'
import { title } from 'process'

export default {
  data() {
    return {
      loading: false,
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 10,
        sortBy: 'email',
        totalItems: 0,
      },
      headers: [
        {
          align: 'left',
          text: 'Email',
          value: 'email',
        },
        {
          align: 'left',
          text: 'Name',
          value: ['firstName', 'lastName'],
          sortable: false,
        },

        {
          align: 'left',
          text: 'Last Login',
          value: 'lastLogin',
        },
        {
          text: 'Shared Trips',
          value: 'sharedTrips',
          sortable: false,
        },
        {
          text: 'Actions',
          value: 'userId',
          sortable: false,
        },
      ],
      sharedContacts: [],
    }
  },
  computed: {
    customerAccount() {
      return this.$store.getters['customerAccounts/getCustomerAccountDetail']
    },
  },
  async mounted() {
    this.getTable()
    EventBus.$on('global-table-view-refresh', () => {
      this.getTable()
    })
  },
  methods: {
    async getTable() {
      this.loading = true

      let sharedContacts = []
      try {
        const eventData = await customerAccounts.getSharedContacts(
          this.customerAccount.id
        )
        sharedContacts = eventData.data
      } catch (error) {
        console.error(error)
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: 'Error fetching shared contacts',
        })
        this.loading = false
      }

      this.sharedContacts = sharedContacts
      this.loading = false
    },
    formatDate(date) {
      return !!date ? DateTime.fromISO(date).toFormat('d MMM yy - h:mm a') : ''
    },
    openSharedTripsSidebar(item) {
      const component = () => import('./SharedContactsSharedTripsSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          row: item,
          title:
            item.firstName || item.lastName
              ? `${item.firstName} ${item.lastName}`
              : item.email,
        },
        persistent: true,
        component,
      })
    },
    openActionsSidebar(item, unshare) {
      const component = () => import('./ShareTripsActionsSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          sharedTrip: item,
          isUnshare: unshare,
          title: `${unshare ? 'Unshare' : 'Share'} Trips`,
        },
        persistent: true,
        component,
      })
    },
  },
}
</script>
