var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.quotes,"pagination":_vm.pagination,"loading":_vm.loading,"totalItems":_vm.totalItems,"disable-initial-sort":""},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',[_c('QuotesTableActions',{attrs:{"row":{
          ...props.item,
          quoteType: 0,
        },"row-props":props}})],1),_c('td',[_c('a',{on:{"click":function($event){return _vm.$router.push({
            name: 'quotes.view',
            params: { id: props.item.quoteId },
          })}}},[_vm._v(" "+_vm._s(props.item.quoteId)+" ")])]),_c('td',[_vm._v(_vm._s(_vm.amount(props.item)))]),_c('td',[_vm._v(_vm._s(_vm.createdOn(props.item)))]),_c('td',[_vm._v(_vm._s(_vm.pickupDate(props.item)))]),_c('td',[_vm._v(_vm._s(_vm.formatQuotePickupDestinationText(props.item)))]),_c('td',[_vm._v(_vm._s(props.item.customerName))]),_c('td',[_vm._v(_vm._s(props.item.customerAccountName))]),_c('td',[_vm._v(_vm._s(_vm.expiration(props.item)))]),_c('td',[_vm._v(_vm._s(_vm.convertedOn(props.item)))])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }