<template>
  <div>
    <v-container fluid>
      <v-layout column wrap class="sheet">
        <v-layout align-center row justify-space-between class="mb-5">
          <v-flex>
            <p class="margin-a-0 font-20">Selected Operators</p>
          </v-flex>
          <v-flex shrink>
            <v-btn
              id="speratorsListAddNew"
              class="btn-primaryaction add-new"
              @click="createItem"
            >
              Add New
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex grow>
            <v-data-table
              :headers="headers"
              :items="effectiveSelectedOperators"
              :items-per-page="5"
              no-data-text="No operators selected"
              class="elevation-0"
              :loading="loading"
            >
              <template #items="props">
                <td class="justify-start align-center layout px-0">
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <span v-on="on">
                        <v-icon
                          :id="`edit-selected-operators-rates-${props.item.externalId}`"
                          small
                          class="mx-1"
                          @click="openEditRatesSidebar(props.item.operatorId)"
                          color="primary"
                          :height="16"
                          :width="16"
                          :disabled="
                            props.item.customerAccountId != customerAccountId
                          "
                        >
                          edit
                        </v-icon>
                      </span>
                    </template>
                    <span>Edit Rates</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <span v-on="on">
                        <v-icon
                          :id="`selectedOperators-unlink-${props.item.externalId}`"
                          small
                          class="mx-1"
                          @click="deleteItem(props.item)"
                          color="primary"
                          :height="16"
                          :width="16"
                          :disabled="
                            props.item.customerAccountId != customerAccountId
                          "
                        >
                          link_off
                        </v-icon>
                      </span>
                    </template>
                    <span>Unlink Operator</span>
                  </v-tooltip>
                </td>
                <td class="text-xs">{{ props.item.operatorName }}</td>
                <td class="text-xs">
                  {{ props.item.operatorNearestMarketName }}
                </td>
                <td class="text-xs">{{ props.item.operatorPhone }}</td>
                <td class="text-xs">
                  <router-link
                    :disabled="
                      props.item.customerAccountId == customerAccountId
                    "
                    :to="`/customer-accounts/view/${props.item.customerAccountId}`"
                  >
                    {{ props.item.customerAccountName }}
                  </router-link>
                </td>
                <td class="text-xs">Market</td>
                <td class="text-xs">
                  {{ formatDate(props.item.startEffective) }}
                </td>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { DateTime } from 'luxon'
import axios from 'axios'
import { EventBus } from '@/utils/event-bus'

export default {
  props: {
    customerAccountId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      effectiveSelectedOperators: [],
    }
  },
  computed: {
    headers() {
      return [
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Name', value: 'operatorName' },
        { text: 'Nearest Market', value: 'operatorNearestMarketName' },
        { text: 'Phone', value: 'operatorPhone' },
        { text: 'Team', value: 'customerAccountName' },
        { text: 'Rates', value: 'operatorRates' },
        { text: 'Selected Date', value: 'startEffective' },
      ]
    },
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
      setSelectedOperatorCompanyId: 'selectedOperator/setCompanyId',
      setSelectedOperatorMode: 'selectedOperator/setMode',
      clearSelectedOperator: 'selectedOperator/clearSelectedOperator',
    }),
    formatDate: (date) =>
      DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT),
    async fetchEffectiveSelectedOperators() {
      if (!this.customerAccountId) {
        return
      }
      this.loading = true

      const response = await axios.get(
        `/customerAccounts/${this.customerAccountId}/effectiveSelectedOperators`
      )

      if (response.status === 200) {
        this.effectiveSelectedOperators = response.data
      }
      this.loading = false
    },
    async deleteItem(selectedOperator) {
      let externalId = selectedOperator.externalId
      console.log('delete', externalId)
      this.loading = true
      const response = await axios.delete(
        `/customerAccounts/${this.customerAccountId}/selectedOperators/${externalId}`
      )
      this.showAlert({
        message: 'Operator unlinked',
        type: 'success',
      })
      if (response.status === 200) {
        this.fetchEffectiveSelectedOperators()
      }
      this.loading = false
    },
    createItem() {
      this.clearSelectedOperator()
      const component = () => import('@/components/SelectedOperatorsForm.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          title: 'Search Operator',
          effectiveSelectedOperatorIds: this.effectiveSelectedOperators.map(
            (eso) => eso.operatorId
          ),
        },
        component,
        persistent: true,
      })
    },
    openEditRatesSidebar(companyId) {
      this.clearSelectedOperator()
      this.setSelectedOperatorCompanyId({ companyId })
      const component = () =>
        import('@/components/EditSelectedOperatorRatesSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          title: 'Edit Rates',
          companyId,
        },
        component,
        persistent: true,
      })
    },
  },
  watch: {
    customerAccountId() {
      this.fetchEffectiveSelectedOperators()
    },
  },
  created() {
    this.fetchEffectiveSelectedOperators()
    EventBus.$on('operator-selected', () => {
      this.fetchEffectiveSelectedOperators()
    })
  },
  destroyed() {
    EventBus.$off('operator-selected')
  },
}
</script>
