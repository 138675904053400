<template>
  <v-container fluid class="base-table-padding-left-none">
    <v-data-table
      id="contractsTable"
      :loading="loading"
      :headers="headers"
      :items="contracts"
      item-key="externalId"
      :pagination.sync="pagination"
    >
      <template #items="props">
        <td class="text-xs">
          <router-link
            class="text-decoration-none"
            target="_blank"
            :to="{
              name: 'contracts.view',
              params: { id: props.item.id },
            }"
          >
            {{ props.item.id }}
          </router-link>
        </td>
        <td class="text-xs">
          {{ props.item.contractName }}
        </td>
        <td class="text-xs">
          <router-link
            class="text-decoration-none"
            target="_blank"
            :to="{
              name: 'users.view',
              params: { id: props.item.sentByUserId },
            }"
          >
            {{ props.item.sentByName }}
          </router-link>
        </td>
        <td class="text-xs">{{ formatDate(props.item.createdOn) }}</td>
        <td class="text-xs">
          <router-link
            class="text-decoration-none"
            target="_blank"
            :to="{
              name: 'customer-accounts.view',
              params: { id: props.item.customerAccountId },
            }"
          >
            {{ props.item.customerAccountName }}
          </router-link>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import customerAccounts from '@/services/customerAccounts'
import { DateTime } from 'luxon'
import { EventBus } from '@/utils/event-bus'

export default {
  data() {
    return {
      loading: false,
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 10,
        sortBy: 'name',
        totalItems: 0,
      },
      headers: [
        {
          align: 'left',
          text: 'ID',
          value: 'managedId',
        },
        {
          align: 'left',
          text: 'Name',
          value: 'contractName',
        },
        {
          align: 'left',
          text: 'Sent By',
          value: 'sentByName',
        },
        {
          align: 'left',
          text: 'Created On',
          value: 'createdOn',
        },
        {
          align: 'left',
          text: 'Customer Account',
          value: 'customerAccountName',
        },
      ],
      contracts: [],
    }
  },
  computed: {
    customerAccount() {
      return this.$store.getters['customerAccounts/getCustomerAccountDetail']
    },
  },
  async mounted() {
    this.getTable()
    EventBus.$on('global-table-view-refresh', () => {
      this.getTable()
    })
  },
  methods: {
    async getTable() {
      this.loading = true
      try {
        const contractResponse = await customerAccounts.getContracts(
          this.customerAccount.id
        )
        this.contracts = contractResponse.data
      } catch (error) {
        console.error(error)
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: 'Error fetching contracts',
        })
        this.loading = false
      }

      this.loading = false
    },
    formatDate(date) {
      return !!date
        ? DateTime.fromISO(date, {
            zone: 'local',
          }).toFormat('MM/dd/yyyy t ZZZZ')
        : ''
    },
  },
}
</script>
