<template>
  <v-container>
    <v-layout row wrap>
      <v-flex>
        <v-layout row wrap>
          <template v-if="row.action === 'delete_customer_detail'">
            <BaseDeletion
              id="customer-account-user-detail"
              :close="closeCard"
              :collection-name="'customer'"
              :row="row"
              :action="action"
            />
          </template>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import customers from '@/services/customers'
import BaseDeletion from '@/components/BaseDeletion.vue'

export default {
  components: {
    BaseDeletion,
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      expanded: this.props.expanded,
      action: (params) => customers.deleteCustomer(params),
    }
  },
  methods: {
    closeCard() {
      this.props.expanded = false
    },
  },
}
</script>
