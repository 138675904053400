var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"base-table-padding-left-none",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"background-primary text-white max-w-80",attrs:{"flat":""},on:{"click":function($event){return _vm.openActionsSidebar('add')}}},[_vm._v(" Add New ")])],1),_c('v-data-table',{attrs:{"id":"riderGroupsTable","loading":_vm.loading,"headers":_vm.headers,"items":_vm.riderGroups,"item-key":"externalId","pagination":_vm.pagination},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',{staticClass:"text-xs"},[_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(props.item.name)+" "),_c('div',{staticClass:"margin-l-2"},[(props.item.isDefaultGroup)?_c('CRTag',{attrs:{"label":"Default","color":"blue"}}):_vm._e()],1)])]),_c('td',{staticClass:"text-xs"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"target":"_blank","to":{
            name: 'customer-accounts.view',
            params: { id: props.item.customerAccount.id },
          }}},[_vm._v(" "+_vm._s(props.item.customerAccount.name)+" ")])],1),_c('td',{staticClass:"text-xs"},_vm._l((props.item.contracts),function(contract){return _c('div',{key:contract.id},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"target":"_blank","to":{
              name: 'contracts.view',
              params: { id: contract.id },
            }}},[_vm._v(" "+_vm._s(contract.name)+" ")])],1)}),0),_c('td',{staticClass:"text-xs"},_vm._l((props.item.domains),function(domain){return _c('div',{key:domain},[_vm._v(" "+_vm._s(domain)+" ")])}),0),_c('td',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.formatDate(props.item.createdOn)))]),_c('td',{staticClass:"text-xs"},[_vm._v(_vm._s(props.item.numRiders))]),_c('td',[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"mx-1",attrs:{"small":""}},on),[_vm._v("more_vert")])]}}],null,true)},[_c('v-list',[_c('v-list-tile',{on:{"click":function($event){return _vm.openActionsSidebar('edit', props.item)}}},[_c('v-list-tile-title',[_vm._v("Edit Rider Group")])],1),(!props.item.isDefaultGroup)?_c('v-list-tile',{on:{"click":function($event){return _vm.openActionsSidebar('delete', props.item)}}},[_c('v-list-tile-title',[_vm._v("Delete Rider Group")])],1):_vm._e()],1)],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }