var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"base-table-padding-left-none",attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"id":"contractsTable","loading":_vm.loading,"headers":_vm.headers,"items":_vm.contracts,"item-key":"externalId","pagination":_vm.pagination},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',{staticClass:"text-xs"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"target":"_blank","to":{
            name: 'contracts.view',
            params: { id: props.item.id },
          }}},[_vm._v(" "+_vm._s(props.item.id)+" ")])],1),_c('td',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.item.contractName)+" ")]),_c('td',{staticClass:"text-xs"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"target":"_blank","to":{
            name: 'users.view',
            params: { id: props.item.sentByUserId },
          }}},[_vm._v(" "+_vm._s(props.item.sentByName)+" ")])],1),_c('td',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.formatDate(props.item.createdOn)))]),_c('td',{staticClass:"text-xs"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"target":"_blank","to":{
            name: 'customer-accounts.view',
            params: { id: props.item.customerAccountId },
          }}},[_vm._v(" "+_vm._s(props.item.customerAccountName)+" ")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }