<template>
  <v-container fluid class="base-table-padding-left-none">
    <div class="d-flex justify-end align-center">
      <CRInput
        v-model="riderSearch"
        class="max-w-200 padding-r-2"
        label="Search Riders"
        placeholder="Search"
        :append-icon="riderSearch ? 'close' : ''"
        @click:append="riderSearch = ''"
      />
      <CRSelect
        v-model="selectedRiderGroupIds"
        key="rider-groups-select"
        class="max-w-200 padding-r-2"
        label="Rider Group Filter"
        placeholder="Select rider group(s)"
        item-text="name"
        item-value="id"
        multiple
        :items="riderGroups"
      />
      <v-btn
        flat
        class="background-primary text-white max-w-80"
        @click="openRiderSidebar('add')"
      >
        Add New
      </v-btn>
      <v-btn
        outline
        flat
        color="primary"
        class="background-white text-primary max-w-80"
        @click="openImportSidebar"
      >
        Import
      </v-btn>
      <v-btn
        flat
        class="background-primary text-white max-w-80"
        @click="exportRiders"
      >
        Export
      </v-btn>
    </div>
    <v-data-table
      id="ridersTable"
      :loading="loading"
      :headers="headers"
      :items="filteredRiders"
      item-key="key"
      :pagination.sync="pagination"
    >
      <template #items="props">
        <td class="text-xs">
          {{ props.item.email }}
        </td>
        <td class="text-xs">
          {{ props.item.firstName }} {{ props.item.lastName }}
        </td>
        <td class="text-xs">
          {{ props.item.phone }}
        </td>
        <td class="text-xs">{{ formatDate(props.item.createdOn) }}</td>
        <td class="text-xs">{{ formatDate(props.item.onboardedOn) }}</td>
        <td class="text-xs">{{ riderStatus(props.item) }}</td>
        <td class="text-xs">
          <div
            v-for="riderGroup in props.item.riderGroups"
            :key="`${props.item.id}-${riderGroup.id}`"
            class="d-flex align-center"
          >
            {{ `${riderGroup.name} (${riderGroup.customerAccountName})` }}
          </div>
        </td>
        <td>
          <v-menu>
            <template #activator="{ on }">
              <v-icon small class="mx-1" v-on="on">more_vert</v-icon>
            </template>
            <v-list>
              <v-list-tile @click="openRiderSidebar('edit', props.item)">
                <v-list-tile-title>Edit Rider</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click="openRiderSidebar('delete', props.item)">
                <v-list-tile-title>Delete Rider</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import customerAccounts from '@/services/customerAccounts'
import rider from '@/services/rider'
import { DateTime } from 'luxon'
import { EventBus } from '@/utils/event-bus'
import { deepClone } from '@/utils/deepClone'
import { v4 } from 'uuid'

export default {
  data() {
    return {
      loading: false,
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 10,
        sortBy: 'email',
        totalItems: 0,
      },
      headers: [
        {
          align: 'left',
          text: 'Email',
          value: 'email',
        },
        {
          align: 'left',
          text: 'Name',
          value: 'name',
        },
        {
          align: 'left',
          text: 'Phone',
          value: 'phone',
        },
        {
          align: 'left',
          text: 'Created On',
          value: 'createdOn',
        },
        {
          align: 'left',
          text: 'Onboarded On',
          value: 'onboardedOn',
        },
        {
          align: 'left',
          text: 'Status',
          sortable: false,
        },
        {
          align: 'left',
          text: 'Rider Groups',
          value: 'riderGroups.name',
        },
        {
          text: 'Actions',
          value: 'userId',
          sortable: false,
        },
      ],
      riders: [],
      selectedRiderGroupIds: [],
      riderSearch: '',
    }
  },
  computed: {
    customerAccount() {
      return this.$store.getters['customerAccounts/getCustomerAccountDetail']
    },
    riderGroups() {
      const allGroups = deepClone(this.riders).flatMap(
        (rider) => rider.riderGroups
      )
      const uniqueGroups = allGroups.reduce(
        (acc, current) => {
          if (!acc.tempIds.includes(current.id)) {
            acc.tempIds.push(current.id)
            acc.result.push(current)
          }
          return acc
        },
        { tempIds: [], result: [] }
      ).result

      uniqueGroups.forEach((group) => {
        if (group.isDefaultGroup) {
          group.name += ` (${group.customerAccountName})`
        }
      })

      return uniqueGroups
    },
    filteredRiders() {
      let filteredRiders = this.riders
      if (this.riderSearch.length >= 3) {
        const riderSearch = this.riderSearch?.toLowerCase()
        filteredRiders = filteredRiders.filter(
          (rider) =>
            rider?.email?.toLowerCase()?.includes(riderSearch) ||
            rider?.firstName?.toLowerCase()?.includes(riderSearch) ||
            rider?.lastName?.toLowerCase()?.includes(riderSearch) ||
            rider?.phone?.includes(this.riderSearch)
        )
      }
      if (this.selectedRiderGroupIds.length > 0) {
        filteredRiders = filteredRiders.filter((rider) =>
          rider.riderGroups.some((group) =>
            this.selectedRiderGroupIds.includes(group.id)
          )
        )
      }
      return filteredRiders || []
    },
  },
  async mounted() {
    this.getTable()
    EventBus.$on('global-table-view-refresh', () => {
      this.getTable()
    })
  },
  methods: {
    async getTable() {
      this.loading = true
      try {
        const ridersResponse = await customerAccounts.getRiders(
          this.customerAccount.id
        )
        this.riders = ridersResponse.data.riders
        this.riders = this.riders.map((rider) => {
          return {
            ...rider,
            key: v4(),
          }
        })
      } catch (error) {
        console.error(error)
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: 'Error fetching rider groups',
        })
        this.loading = false
      }

      this.loading = false
    },
    formatDate(date) {
      return !!date
        ? DateTime.fromISO(date, {
            zone: 'local',
          }).toFormat('MM/dd/yyyy t ZZZZ')
        : ''
    },
    riderStatus(rider) {
      if (!!rider.userLastLogin) {
        return 'Onboarded'
      }
      if (rider.inviteSentOn) {
        return 'Invited'
      }
      return 'Not Onboarded'
    },
    openRiderSidebar(mode, rider = null) {
      const component = () => import('./CustomerAccountRiderSidebar.vue')
      let title = ''
      switch (mode) {
        case 'add':
          title = 'Add Rider'
          break
        case 'edit':
          title = 'Edit Rider'
          break
        case 'delete':
          title = 'Delete Rider'
          break
      }
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          customerAccountId: this.customerAccount.id,
          mode: mode,
          rider: rider,
          title: title,
        },
        persistent: true,
        component,
      })
    },
    openImportSidebar() {
      const component = () => import('./ImportRiderSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          customerAccountId: this.customerAccount.id,
          title: 'Import Riders',
        },
        persistent: true,
        component,
      })
    },
    async exportRiders() {
      try {
        const exportData = await rider.exportRiders(this.customerAccount.id)
        var blob = new Blob([exportData.data], {
          type: 'text/csv;charset=utf-8',
        })
        await saveAs(blob, 'RidersExport')
      } catch (e) {
        console.warn('Export rider data failed.')
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: 'Error exporting riders',
        })
      }
    },
  },
}
</script>
