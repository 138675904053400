<template>
  <v-data-table
    :headers="headers"
    :items="reservations"
    :loading="loading"
    :pagination.sync="pagination"
    :total-items="totalItems"
    disable-initial-sort
  >
    <template #items="props">
      <td>{{ props.item.managedId }}</td>
      <td>{{ props.item.reservationStatusLabel }}</td>
      <td>{{ route(props.item) }}</td>
      <td>{{ props.item.tripTypeLabel }}</td>
      <td>{{ pickupDate(props.item) }}</td>
      <td>{{ pickupTime(props.item) }}</td>
      <td>{{ price(props.item) }}</td>
      <td>{{ props.item.paymentStatus }}</td>
      <td>
        {{ props.item.customerFirstName }} {{ props.item.customerLastName }}
      </td>
      <td>{{ props.item.customerAccountName }}</td>
      <td>
        <v-btn
          color="primary"
          flat
          @click="
            $router.push({
              name: 'reservation-detail',
              params: { id: props.item.reservationId },
            })
          "
        >
          Details
        </v-btn>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { phoneFormatFilter } from '@/utils/phone'
import { filter } from '@/utils/filter'
import {
  currencyFilter,
  expirationDelta,
  formatQuotePickupDestinationText,
  getPickupDestinationCitiesFromAddresses,
} from '@/utils/string'
import { DateTime } from 'luxon'
import { reservationsCustomerAccountTableView } from '@/services/charterup/reservations'
import { sort } from '@/utils/sort'

export default {
  data() {
    return {
      pagination: {
        page: 1,
        rowsPerPage: 10,
      },
      totalItems: 0,
      loading: false,
      formatQuotePickupDestinationText,
      reservations: [],
      headers: [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
          sortable: true,
        },
        {
          text: 'Trip Status',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Route',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Trip Type',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Pickup Date',
          align: 'left',
          value: 'startDate',
          sortable: true,
        },
        {
          text: 'Pickup Time',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Price',
          align: 'left',
          value: 'customerTotal',
          sortable: true,
        },
        {
          text: 'Status',
          align: 'left',
          value: 'paymentStatus',
          sortable: true,
        },
        {
          text: 'Booking Contact',
          align: 'left',
          value: 'customerFirstName',
          sortable: true,
        },
        {
          text: 'Team',
          align: 'left',
          value: 'customerAccountName',
          sortable: true,
        },
        {
          text: '',
          align: 'left',
          sortable: false,
        },
      ],
    }
  },
  props: {
    customerAccountId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.fetchReservationsList()
  },
  watch: {
    pagination: {
      handler() {
        this.fetchReservationsList()
      },
      deep: true,
    },
  },
  methods: {
    route(reservation) {
      if (reservation.tripRouteName) {
        return reservation.tripRouteName
      }

      const minOrderIndex = Math.min(
        ...reservation.stops.map((stop) => stop.orderIndex)
      )
      const nextOrderIndex = minOrderIndex + 1
      const pickup = reservation.stops.find(
        (stop) => stop.orderIndex === minOrderIndex
      )
      const dropoff = reservation.stops.find(
        (stop) => stop.orderIndex === nextOrderIndex
      )
      const cities = getPickupDestinationCitiesFromAddresses(
        pickup.address,
        dropoff.address
      )

      return `${cities.pickup} > ${cities.dropoff}`
    },
    price(reservation) {
      return currencyFilter(reservation.customerTotal)
    },
    pickupDate(reservation) {
      return DateTime.fromISO(reservation.startDate, {
        zone: reservation.firstStopAddressTimeZone,
      }).toLocaleString(DateTime.DATE_SHORT)
    },
    pickupTime(reservation) {
      const firstPickup = reservation.trips?.[0]?.stops?.find(
        (stop) => stop.orderIndex === 0
      )
      return DateTime.fromISO(reservation.startDate, {
        zone: reservation.firstStopAddressTimeZone,
      }).toLocaleString(DateTime.TIME_SIMPLE)
    },
    async fetchReservationsList() {
      const { sortBy, descending, page, rowsPerPage } = this.pagination

      const filterUtil = filter()

      const reservationTypeFilter = {
        column: {
          _t_id: 'c9e636ff-f206-4f99-a39f-bb433ad6b700',
          prop: 'reservationType',
          filterType: 'eq',
        },
        value: 0,
      }

      const parent = filterUtil.createParent('and')
      filterUtil.add(parent, reservationTypeFilter)

      let params = {
        customerAccountId: this.customerAccountId,
        page,
        pageSize: rowsPerPage,
        filters: filterUtil.asQueryParams(),
      }

      if (sortBy !== null) {
        const quoteSort = sort()
        quoteSort.add({
          prop: sortBy,
          direction: descending ? 'desc' : 'asc',
        })
        params = {
          ...params,
          sorts: quoteSort.asQueryParams(),
        }
      }

      this.loading = true
      const resp = await reservationsCustomerAccountTableView(params)
      this.loading = false

      if (!resp.data?.resultList) {
        this.reservations = []
        this.pagination.page = 1
        return
      }

      this.reservations = resp.data.resultList
      this.totalItems = resp.data.count
    },
  },
}
</script>
