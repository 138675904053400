import axios from 'axios'
import { baseUrl } from '@/utils/env'

export const reservationsCustomerAccountTableView = (params) => {
  const { customerAccountId, pageSize, page, filters, sorts } = params

  let query = `page=${page}&pageSize=${pageSize}`

  if (filters) {
    query += `&${filters}`
  }

  if (sorts) {
    query += `&${sorts}`
  }

  query = encodeURI(query)

  return axios.get(
    `https://${baseUrl()}/referrals/customerAccount/${customerAccountId}?${query}`
  )
}

export const reservationById = (reservationId) => {
  const url = `https://${baseUrl()}/reservations/v2/${reservationId}?viewRejectedReferrals=true`
  const data = {
    method: 'GET',
    url,
  }
  return axios(data)
}
